import { useTranslation } from 'react-i18next';

import { IconPerson } from '@agentero/icons';

import { AgentLicenseAgentActions } from 'modules/agents/agentProfile/agentLicense/agentLicenseActions/AgentLicenseAgentActions';
import { useAgentLicenseQuery } from 'packages/services/fetch/agent-fe/agent-license/useAgentLicenseQuery';

import { ComplianceLicenseGlobalStatus } from '../compliance/complianceLicense/ComplianceLicenseGlobalStatus';
import { ComplianceWidgetItem } from './ComplianceWidgetItem';

type ComplianceWidgetNoAdminItemProps = {
	agentId: string;
};

export const ComplianceWidgetNoAdminItem = ({ agentId }: ComplianceWidgetNoAdminItemProps) => {
	const { t } = useTranslation('agencies');
	const { data: license } = useAgentLicenseQuery(agentId);

	return (
		<ComplianceWidgetItem
			icon={<IconPerson />}
			title={t('complianceWidget.agentLicenses')}
			status={<ComplianceLicenseGlobalStatus globalStatus={license.globalStatus} />}
			actions={<AgentLicenseAgentActions globalStatus={license.globalStatus} agentId={agentId} />}
		/>
	);
};
