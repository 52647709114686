import { Agent } from '@agentero/models';
import { isComplianceMissing, isSuperAdmin } from '@agentero/models/agent';

type IsRedirectToOnboardingWizard = {
	agent: Agent;
	isWizardFlagEnabled: boolean;
	isSkipVerificationCookie: boolean;
};

export const isComplianceWizardRequired = ({
	agent,
	isWizardFlagEnabled,

	isSkipVerificationCookie
}: IsRedirectToOnboardingWizard) =>
	isWizardFlagEnabled &&
	!isSuperAdmin(agent.role) &&
	!agent.isImpersonation &&
	!isSkipVerificationCookie &&
	isComplianceMissing(agent.compliance, agent.role);
