import { useTranslation } from 'react-i18next';

import { EOStatus } from '@agentero/models/agency';
import { Tag, TagColor } from '@agentero/ui';

type AgencyEOStatusLabelProps = {
	status: EOStatus;
};

export const AgencyEOStatusLabel = ({ status }: AgencyEOStatusLabelProps) => {
	const { t } = useTranslation('agencies');

	return (
		<Tag style={{ margin: '2px 0' }} color={tagColorMapping[status]} role="alert">
			{t(`agencyErrorsAndOmissions.status.${status}`)}
		</Tag>
	);
};

const tagColorMapping: { [key in EOStatus]: TagColor } = {
	[EOStatus.Approved]: 'green',
	[EOStatus.InReview]: 'orange',
	[EOStatus.Invalid]: 'red',
	[EOStatus.Unknown]: 'gray'
};
