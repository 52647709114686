import { GetAgentComplianceInformationResponse } from '@agentero/grpc-clients/grpc/agent-fe';

import { ComplianceLicense, NPN_HELP_HREF, parseComplianceLicense } from './ComplianceLicense';

export type AgentComplianceLicense = ComplianceLicense & {
	ssn?: string;
};

export const parseAgentComplianceLicense = (
	license: GetAgentComplianceInformationResponse.AsObject
): AgentComplianceLicense => ({
	...parseComplianceLicense(license),
	ssn: license.ssn
});

export const getAgentComplianceLicenseGeneralInformation = (
	{ npn, licenseState, licenseNumber }: AgentComplianceLicense,
	statesKeys: { [key: string]: string }
) => [
	{
		label: 'compliance.license.fields.agentNpn',
		value: npn,
		tooltip: {
			key: 'compliance.license.npnTooltip',
			href: NPN_HELP_HREF
		}
	},
	{
		label: 'compliance.license.fields.mainLicenseState',
		value: statesKeys[licenseState]
	},
	{
		label: 'compliance.license.fields.licenseNumber',
		value: licenseNumber
	}
];
