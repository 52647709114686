import { GetAssignedADMCalendarResponse } from '@agentero/grpc-clients/grpc/agent-fe';

export type AgentCalendar = {
	onboardingCalendarLink: string;
	reengagemntCalendarLink: string;
};

export const parseAgentCalendar = (
	response: GetAssignedADMCalendarResponse.AsObject
): AgentCalendar => {
	return {
		onboardingCalendarLink: response.onboardingLink,
		reengagemntCalendarLink: response.reengagementLink
	};
};
