import { CarrierItem } from '@agentero/models';

import {
	AgenteroUserData,
	FetchAgQueryOptions,
	GrpcPortalRequest,
	UseAgQueryOptions,
	fetchAgQuery,
	useAgQuery
} from '../../useAgQuery';
import { FetchCarriersArgs, fetchCarrierList } from './fetchCarrierList';

export const carrierList = 'carrier-list';

const carrierListQueryConfiguration: GrpcPortalRequest<FetchCarriersArgs, CarrierItem[]> = {
	request: fetchCarrierList,
	key: ({ userId, search }) => [carrierList, userId, search]
};

export const useCarrierListQuery = (
	search: FetchCarriersArgs,
	config?: UseAgQueryOptions<CarrierItem[]>
) => useAgQuery(carrierListQueryConfiguration, search, config);

export const fetchCarrierListQuery = (
	user: AgenteroUserData,
	search: FetchCarriersArgs,
	config?: FetchAgQueryOptions<CarrierItem[]>
) => fetchAgQuery(carrierListQueryConfiguration, { ...user, ...search }, config);
