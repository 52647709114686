import { AgentCalendar } from '@agentero/models/agent';

import { GrpcPortalRequest, useAgQuery } from '../../useAgQuery';
import { fetchAssignedADMCalendar } from './fetchAssignedADMCalendar';

export const assignedADMCalendarKey = 'assigned-adm-calendar';

const assignedADMCalendarConfig: GrpcPortalRequest<never, AgentCalendar> = {
	request: fetchAssignedADMCalendar,
	key: ({ userId }) => [assignedADMCalendarKey, userId]
};

export const useAssignedADMCalendarQuery = () => useAgQuery(assignedADMCalendarConfig);
