import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { IconFindInPage } from '@agentero/icons';
import { EOStatus } from '@agentero/models/agency';
import { Button } from '@agentero/ui';

import { getSettingsAgencyEORoutePath } from 'routes';

type AgencyEOActionsProps = {
	agencyId: string;
	eoStatus: EOStatus;
	onlyEditActions?: boolean;
};

export const AgencyEOActions = ({
	agencyId,
	eoStatus,
	onlyEditActions = false
}: AgencyEOActionsProps) => {
	const { t } = useTranslation('shared');

	return !onlyEditActions ||
		(onlyEditActions && [EOStatus.Invalid, EOStatus.Unknown].includes(eoStatus)) ? (
		<Link href={getSettingsAgencyEORoutePath(agencyId)} passHref legacyBehavior>
			<Button as="a" variant="secondary">
				<IconFindInPage />
				{t('review')}
			</Button>
		</Link>
	) : null;
};
