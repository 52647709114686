import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { IconEdit, IconFindInPage, IconSearch } from '@agentero/icons';
import { isSuperAdmin } from '@agentero/models/agent';
import { ComplianceLicenseGlobalStatus, isLicenseMissing } from '@agentero/models/compliance';
import { Button } from '@agentero/ui';

import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { getSettingsAgentLicenseEditRoutePath, getSettingsAgentLicenseRoutePath } from 'routes';

type AgentLicenseAgentActionsProps = {
	agentId: string;
	globalStatus: ComplianceLicenseGlobalStatus;
	onlyEditActions?: boolean;
};

export const AgentLicenseAgentActions = ({
	agentId,
	globalStatus,
	onlyEditActions = false
}: AgentLicenseAgentActionsProps) => {
	const { t } = useTranslation('shared');
	const {
		data: { role }
	} = useAgentResource();

	const isComplianceEditable = isLicenseMissing(globalStatus);
	const isSuperAdminRole = isSuperAdmin(role);

	return (
		<>
			{isComplianceEditable ? (
				<Link href={getSettingsAgentLicenseEditRoutePath(agentId)} passHref legacyBehavior>
					{isSuperAdminRole ? (
						<Button variant="secondary" as="a">
							<IconEdit />
							{t('edit')}
						</Button>
					) : (
						<Button variant="secondary" as="a">
							<IconSearch />
							{t('compliance.license.find-your-license')}
						</Button>
					)}
				</Link>
			) : !onlyEditActions ? (
				<>
					{isSuperAdminRole && (
						<Link href={getSettingsAgentLicenseEditRoutePath(agentId)} passHref legacyBehavior>
							<Button variant="secondary" as="a">
								<IconEdit />
								{t('edit')}
							</Button>
						</Link>
					)}
					<Link href={getSettingsAgentLicenseRoutePath(agentId)} passHref legacyBehavior>
						<Button variant="secondary" as="a">
							<IconFindInPage />
							{t('viewDetals')}
						</Button>
					</Link>
				</>
			) : null}
		</>
	);
};
