import { useTranslation } from 'react-i18next';

import {
	ComplianceLicenseGlobalStatus as LicenseGlobalStatus,
	getComplianceGlobalStatusKey,
	getComplianceGlobalTagColor
} from '@agentero/models/compliance';
import { Tag } from '@agentero/ui';

type ComplianceLicenseGlobalStatusProps = {
	globalStatus: LicenseGlobalStatus;
};

export const ComplianceLicenseGlobalStatus = ({
	globalStatus
}: ComplianceLicenseGlobalStatusProps) => {
	const { t } = useTranslation('shared');

	if (globalStatus === LicenseGlobalStatus.None) return null;

	return (
		<Tag
			style={{ margin: '2px 0' }}
			color={getComplianceGlobalTagColor(globalStatus)}
			role="status">
			{t(getComplianceGlobalStatusKey(globalStatus))}
		</Tag>
	);
};
