import { GetAssignedADMCalendarRequest } from '@agentero/grpc-clients/grpc/agent-fe';
import { AgentCalendar, parseAgentCalendar } from '@agentero/models/agent';
import { handleGrpcResponse } from '@agentero/service-errors';

import { agentFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';

export const fetchAssignedADMCalendar = ({ token, userId }: AgenteroUserData) => {
	return new Promise<AgentCalendar>((resolve, reject) => {
		const request = new GetAssignedADMCalendarRequest();
		request.setAgentId(userId);

		agentFrontendService.getAssignedADMCalendar(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}
				resolve(parseAgentCalendar(response.toObject()));
			}
		);
	});
};
