import { UseQueryOptions } from '@tanstack/react-query';

import { AgentComplianceLicense } from '@agentero/models/compliance';

import { AgenteroUserData, GrpcPortalRequest, fetchAgQuery, useAgQuery } from '../../useAgQuery';
import { fetchAgentLicense } from './fetchAgentLicense';

export const agentLicenseResourceKey = 'agent-license';

export type AgentLicenseRequest = {
	agentId: string;
	fullInfo?: boolean;
};

const agentLicenseConfig: GrpcPortalRequest<AgentLicenseRequest, AgentComplianceLicense> = {
	request: fetchAgentLicense,
	key: ({ agentId, userId, fullInfo }) => [
		agentLicenseResourceKey,
		userId,
		agentId,
		fullInfo ? 'full-info' : 'info'
	]
};

export const prefetchAgentLicense = ({
	agentId,
	fullInfo,
	token,
	userId
}: AgentLicenseRequest & AgenteroUserData) =>
	fetchAgQuery(agentLicenseConfig, { agentId, token, userId, fullInfo });

export const useAgentLicenseQuery = (
	agentId: string,
	fullInfo?: boolean,
	config?: UseQueryOptions<AgentComplianceLicense>
) => useAgQuery(agentLicenseConfig, { agentId, fullInfo }, config);
