import { GetAgentComplianceInformationRequest } from '@agentero/grpc-clients/grpc/agent-fe';
import { AgentComplianceLicense, parseAgentComplianceLicense } from '@agentero/models/compliance';
import { handleGrpcResponse } from '@agentero/service-errors';

import { agentFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';
import { AgentLicenseRequest } from './useAgentLicenseQuery';

export const fetchAgentLicense = ({
	token,
	userId,
	agentId,
	fullInfo
}: AgentLicenseRequest & AgenteroUserData) => {
	return new Promise<AgentComplianceLicense>((resolve, reject) => {
		const request = new GetAgentComplianceInformationRequest();
		request.setAgentId(agentId);
		request.setFullInfo(fullInfo);

		agentFrontendService.getAgentComplianceInformation(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				const agentCompliance = parseAgentComplianceLicense(response.toObject());

				resolve(agentCompliance);
			}
		);
	});
};
