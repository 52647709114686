import { NextPageContext } from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

const skipRedirectUrlCookieKey = 'redirectUrl';

export const destroyRedirectCookie = () => {
	destroyCookie(undefined, skipRedirectUrlCookieKey, {
		path: '/'
	});
};

export const getRedirectUrlCookie = (ctx: NextPageContext) => {
	const cookies = parseCookies(ctx);
	return cookies[skipRedirectUrlCookieKey] || '';
};

export const setRedirectUrlCookie = (urlRedirect: string) => {
	const oneHour = 60 * 60;

	setCookie(undefined, skipRedirectUrlCookieKey, urlRedirect, {
		path: '/',
		httpOnly: false,
		maxAge: oneHour
	});
};

const skipVerificationCookieKey = 'skipVerification';

export const setSkipVerificationCookie = () => {
	const oneDay = 60 * 60 * 24;

	setCookie(undefined, skipVerificationCookieKey, 'true', {
		path: '/',
		httpOnly: false,
		maxAge: oneDay
	});
};

export const getSkipVerificationCookie = (ctx: NextPageContext) => {
	const cookies = parseCookies(ctx);
	return !!cookies[skipVerificationCookieKey];
};

export const destroyGetSkipVerificationCookie = () => {
	destroyCookie(undefined, skipVerificationCookieKey, {
		path: '/'
	});
};
