import { ReactNode } from 'react';

import { Box, HStack, Stack } from '@agentero/styles/jsx';
import { Avatar, Text } from '@agentero/ui';

type ComplianceWidgetItemProps = {
	icon: ReactNode;
	title: string;
	status: ReactNode;
	actions?: ReactNode;
};

export const ComplianceWidgetItem = ({
	icon,
	title,
	status,
	actions
}: ComplianceWidgetItemProps) => {
	return (
		<Stack height="100%" gap="40">
			<HStack gap="16">
				<Avatar fallback={icon} size="lg" />
				<Stack gap="4" flex="1">
					<Text>
						<b>{title}</b>
					</Text>
					<div>{status}</div>
				</Stack>
				{actions && <Box>{actions}</Box>}
			</HStack>
		</Stack>
	);
};
