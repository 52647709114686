import { useTranslation } from 'react-i18next';

import { IconDescription, IconDomain, IconPerson } from '@agentero/icons';
import { OperateAs } from '@agentero/models/agency';
import {
	getAgencyAdminLicenseGlobalStatus,
	getAgencyLicenseGlobalStatus,
	getAgentLicenseGlobalStatus,
	isAgencyAdmin
} from '@agentero/models/agent';
import { Divider, Stack } from '@agentero/styles/jsx';

import { AgencyEOActions } from 'modules/agencies/agencyProfile/agencyEO/AgencyEOActionsContainer/AgencyEOActions';
import { AgencyEOStatusLabel } from 'modules/agencies/agencyProfile/agencyEO/AgencyEOBodyStatusLabel/AgencyEOStatusLabel';
import { AgencyLicenseAgentActions } from 'modules/agencies/agencyProfile/agencyLicense/agencyLicenseActions/AgencyLicenseAgentActions';
import { AgentLicenseAgentActions } from 'modules/agents/agentProfile/agentLicense/agentLicenseActions/AgentLicenseAgentActions';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';

import { ComplianceLicenseGlobalStatus } from './compliance/complianceLicense/ComplianceLicenseGlobalStatus';
import { ComplianceWidgetItem } from './complianceWidget/ComplianceWidgetItem';
import { ComplianceWidgetNoAdminItem } from './complianceWidget/ComplianceWidgetNoAdminItem';

type ComplianceWidgetProps = {
	onlyEditActions?: boolean;
};

export const ComplianceWidget = ({ onlyEditActions }: ComplianceWidgetProps) => {
	const { t } = useTranslation('agencies');
	const {
		data: { id: agentId, role, compliance, agency }
	} = useAgentResource();
	const { eoStatus, operateAs } = compliance;

	const isAgencyAdminRole = isAgencyAdmin(role);
	const agentGlobalLicenseStatus = getAgentLicenseGlobalStatus(compliance);
	const agencyAdminGlobalLicenseStatus = getAgencyAdminLicenseGlobalStatus(
		compliance,
		isAgencyAdminRole
	);
	const agencyGlobalLicenseStatus = getAgencyLicenseGlobalStatus(compliance);

	return (
		<Stack gap="16" marginBlock="auto">
			{!isAgencyAdminRole && (
				<>
					<ComplianceWidgetNoAdminItem agentId={agentId} />
					<Divider color="border" />
				</>
			)}
			<ComplianceWidgetItem
				icon={<IconPerson />}
				title={t(
					`complianceWidget.${isAgencyAdminRole ? 'agentLicenses' : 'principalAgentLicenses'}`
				)}
				status={
					<ComplianceLicenseGlobalStatus
						globalStatus={
							isAgencyAdminRole ? agentGlobalLicenseStatus : agencyAdminGlobalLicenseStatus
						}
					/>
				}
				actions={
					isAgencyAdminRole && (
						<AgentLicenseAgentActions
							onlyEditActions={onlyEditActions}
							globalStatus={agentGlobalLicenseStatus}
							agentId={agentId}
						/>
					)
				}
			/>
			{operateAs === OperateAs.Agency && (
				<>
					<Divider color="border" />
					<ComplianceWidgetItem
						icon={<IconDomain />}
						title={t('complianceWidget.agencyLicenses')}
						status={<ComplianceLicenseGlobalStatus globalStatus={agencyGlobalLicenseStatus} />}
						actions={
							isAgencyAdminRole && (
								<AgencyLicenseAgentActions
									onlyEditActions={onlyEditActions}
									globalStatus={agencyGlobalLicenseStatus}
									agencyId={agency.id}
								/>
							)
						}
					/>
				</>
			)}
			<Divider color="border" />
			<ComplianceWidgetItem
				icon={<IconDescription />}
				title={t('complianceWidget.eao')}
				status={<AgencyEOStatusLabel status={eoStatus} />}
				actions={
					isAgencyAdminRole && (
						<AgencyEOActions
							eoStatus={eoStatus}
							onlyEditActions={onlyEditActions}
							agencyId={agency.id}
						/>
					)
				}
			/>
		</Stack>
	);
};
